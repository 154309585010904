<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-24 15:27:12
 * @LastEditTime: 2021-09-26 12:00:35
 * @Description: 合同价格表-查看场景
 * @Param:
 * @FilePath: \JT_Web_test\src\views\salesBusiness\contractManage\contractPrice\contractPriceForm_see.vue
 -->

<template>
    <div class="contractPriceForm_see" v-loading="loading">
        <!-- 基础信息 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="4" class="table-tit">
                            基础信息
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width:1.5rem;">
                            合同编号:
                        </td>
                        <td>{{ formData.contract_number }}</td>
                        <td class="column-tit" style="width:1.5rem;">
                            工程名称：
                        </td>
                        <td>{{ formData.engineering_name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 产品标号设置 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="3" class="table-tit">
                            产品标号设置
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width: 1rem;">
                            产品标号
                        </td>
                        <td class="column-tit" style="width: 1rem;">
                            价格(元)
                        </td>
                        <td class="column-tit" style="width: 2rem;">
                            附加型号
                        </td>
                    </tr>
                    <template v-if="formData.ContractPriceMarkNumList.length>0">
                        <tr
                            v-for="(item, index) in formData.ContractPriceMarkNumList"
                            :key="`productNum_${index}`"
                        >
                            <td>{{ item.product_mark_num }}</td>
                            <td>{{ item.product_mark_price }}</td>
                            <td>
                                <div>
                                    <ul class="tag-con clearfix">
                                        <template v-if="item.additionalList">
                                            <template v-for="(tag, tagindex) in item.additionalList">
                                                <li
                                                    class="tag el_left"
                                                    :key="`${item.product_mark_num}_${tagindex}`"
                                                    v-if="tagindex < 6"
                                                >
                                                    <span class="tag-text" :title="tag.additional_model">{{ tag.additional_model }}</span>
                                                </li>
                                            </template>
                                            <li class="tag el_left tag-more" @click="viewMoreTag(item)" v-if="item.additionalList.length>6">
                                                <span class="tag-more-text">查看更多</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="5" class="table-tit">
                            附加型号设置
                        </td>
                    </tr>
                    <template v-if="formData.ContractPriceAdditionalList.length>0">
                        <template v-for="(item, index) in formData.ContractPriceAdditionalList">
                            <tr :key="`additional_${index}`">
                                <td class="column-tit" style="width:1.5rem;">
                                    附加型号
                                </td>
                                <td>{{ item.additional_model }}</td>
                                <td>
                                    <el-checkbox
                                        v-model="item.checkAll"
                                        disabled
                                    >
                                        所有标号
                                    </el-checkbox>
                                </td>
                                <td class="column-tit" style="width:1.5rem;">
                                    价格
                                </td>
                                <td>{{ item.additional_model_price }}</td>
                            </tr>
                            <tr v-if="!item.checkAll" :key="`link_${index}`">
                                <td class="column-tit" style="width:1.5rem;">
                                    产品标号
                                </td>
                                <td colspan="4">
                                    <el-checkbox-group v-model="item.checkedProductNumList" class="checkgroup" disabled>
                                        <el-checkbox
                                            v-for="(v, j) in item.productNumChosed"
                                            :key="`productLabel_${j}`"
                                            :label="v.cpmvid"
                                        >
                                            {{ v.product_mark_num }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- 泵送价格 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="4" class="table-tit">
                            泵送价格
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            我方提供泵送:
                        </td>
                        <td>
                            <el-radio-group v-model="formData.provide_pump" disabled>
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <td class="column-tit">
                            地泵费(元/m³):
                        </td>
                        <td style="width:1rem;" colspan="2">
                            {{ formData.ground_pump_price }}
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            泵送补充模式：
                        </td>
                        <td>
                            <el-radio-group v-model="formData.pump_charge_way" disabled>
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.pump_charge_way===1">
                            <td class="column-tit" colspan="2">
                                不足<el-input
                                    placeholder="请输入"
                                    v-model="formData.pump_min"
                                    disabled
                                    type="number"
                                    class="line-input"
                                ></el-input> 方，
                                按<el-input
                                    placeholder="请输入"
                                    v-model="formData.pump_add"
                                    disabled
                                    type="number"
                                    class="line-input"
                                ></el-input>方结算
                            </td>
                        </template>
                        <template v-if="formData.pump_charge_way===2">
                            <td colspan="2">
                                不足<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.pump_volumn_less"
                                    type="number"
                                    disabled
                                ></el-input>
                                方，收取<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.pump_plus_money"
                                    type="number"
                                    disabled
                                ></el-input>元。
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td class="column-tit" colspan="4">
                            <el-radio-group v-model="formData.is_pump_base" disabled>
                                <el-radio :label="true">
                                    统一泵送价格
                                    <el-input
                                        placeholder="请输入"
                                        class="line-input"
                                        v-model="formData.pump_price"
                                        type="number"
                                        disabled
                                    ></el-input>
                                    <label>元/m³</label>
                                </el-radio>
                                <el-radio :label="false">
                                    泵送高度区间配置
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="column-tit">
                            泵送高度(m):
                        </td>
                        <td colspan="2" class="column-tit">
                            泵送价格(元/m³):
                        </td>
                    </tr>
                    <tr v-for="pump in formData.ContractPricePumpList" :key="pump.cppiid">
                        <td colspan="2" class="pump-select">
                            <el-select
                                v-model="pump.pump_lower"
                                disabled
                            >
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                            -
                            <el-select
                                v-model="pump.pump_upper"
                                placeholder=""
                                disabled
                            >
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td colspan="2">
                            <el-input
                                placeholder="请输入"
                                v-model="pump.pump_price"
                                disabled
                                type="number"
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 运输费用设置 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="6" class="table-tit">
                            运输费用设置
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width:1.5rem;">
                            我方运输：
                        </td>
                        <td class="column-tit">
                            是否含运费：
                        </td>
                        <td colspan="4">
                            <el-radio-group v-model="formData.is_included_freight" disabled>
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            基础运费(元/m³):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.base_freight"
                                disabled
                                type="number"
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            基础公里数(km):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.base_mileage"
                                disabled
                                type="number"
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            超出运距费用(元/m³·km):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.than_distance"
                                disabled
                                type="number"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            费用补充模式：
                        </td>
                        <td colspan="2">
                            <el-radio-group v-model="formData.compensate_charge_way" disabled>
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.compensate_charge_way===1">
                            <td class="column-tit" colspan="3">
                                每车不足<el-input
                                    placeholder="请输入"
                                    v-model="formData.compensate_capacity"
                                    disabled
                                    type="number"
                                    class="line-input"
                                ></el-input> m³，
                                按<el-input
                                    placeholder="请输入"
                                    v-model="formData.bv_volumn_add"
                                    disabled
                                    class="line-input"
                                    type="number"
                                ></el-input>m³收取运费。
                            </td>
                        </template>
                        <template v-if="formData.compensate_charge_way===2">
                            <td colspan="3">
                                单次方不足<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.bp_volumn_less"
                                    type="number"
                                    disabled
                                ></el-input>
                                m³,按实际方量价格结算后加收<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.plus_money"
                                    type="number"
                                    disabled
                                ></el-input>元。
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="1" class="column-tit">
                            对方自运(元/m³)
                        </td>
                        <td colspan="5">
                            <el-input
                                placeholder="请输入"
                                type="number"
                                v-model="formData.transport_price"
                                disabled
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
            height="30%"
            :modal="false"
            center
        >
            <ul>
                <li
                    class="tag el_left"
                    v-for="(tag, index) in currentNumMore.additionalList"
                    :key="`more_${index}`"
                >
                    <span class="tag-text" :title="tag.additional_model">{{ tag.additional_model }}</span>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            formData: {
                ContractPriceMarkNumList: [],
                ContractPriceAdditionalList: [],
                ContractPriceAdditionalMarkList: [],
                ContractPricePumpList: [],
            }, // 总数据集合
            productNumChosed: [], // 已添加的标号
            dialogVisible: false, // 更多附加型号弹框显示隐藏
            currentNumMore: {}, // 当前要弹出更多型号的标号数据
            dialogTitle: '', // 更多附加型号标题
            pumpingHeightSelectData: [], // 泵送字典数据列表
        };
    },
    watch: {
    },
    computed: {},
    created() {
        // 获取泵送字典价格
        this.getPumpList();
    },
    mounted() {
        if (this.extr?.formInfo?.key_value) {
            this.initPage(this.extr.formInfo.key_value);
        }
    },
    methods: {
        initPage(cpvid) {
            this.loading = true;
            this.$axios.get(`/interfaceApi/sale/contract_price/version?cpvid=${cpvid}`)
                .then(res => {
                    if (res) {
                        this.formData = res;
                        // 初始化-费用补充方式
                        if (!this.formData.compensate_charge_way) {
                            this.formData.compensate_charge_way = 1;
                        }
                        // 初始化-是否自运
                        if (this.formData.is_self_carriage === null) {
                            this.formData.is_self_carriage = false;
                        }
                        // 初始化-是否含运费
                        if (this.formData.is_included_freight === null) {
                            this.formData.is_included_freight = true;
                        }
                        const chosedArr = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList)); // 新数组
                        this.productNumChosed = chosedArr;
                        if (this.formData.ContractPriceAdditionalMarkList.length > 0) {
                            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
                            this.renderAdditionalNumcheck();
                        }
                    }
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 回显-型号选中标号
        renderAdditionalNumcheck() {
            const linkClassByAdditional = this.linkClassByAdditional(this.formData.ContractPriceAdditionalMarkList);
            this.formData.ContractPriceAdditionalList.map(item => {
                if (linkClassByAdditional[item.cpavid]) {
                    let arr = [];
                    arr = linkClassByAdditional[item.cpavid];
                    const list = [];
                    arr.map(v => {
                        list.push(v.cpmvid);
                    });
                    item.checkedProductNumList = list;
                    if (list.length === this.productNumChosed.length) {
                        item.checkAll = true;
                    } else {
                        item.checkAll = false;
                    }
                } else {
                    item.checkAll = false;
                    item.checkedProductNumList = [];
                }
            });
        },

        // 查看更多附加型号
        viewMoreTag(data) {
            this.dialogTitle = data.product_mark_num + '-附加型号';
            this.dialogVisible = true;
            this.currentNumMore = data;
        },

        // 关系集合-按标号分组
        linkClassByNum(oldArray) {
            if (oldArray.length > 0) {
                const linkByNumData = {};
                for (const item of oldArray) {
                    if (!linkByNumData[item.cpmvid]) {
                        linkByNumData[item.cpmvid] = [];
                        linkByNumData[item.cpmvid].push(item);
                    } else {
                        linkByNumData[item.cpmvid].push(item);
                    }
                }
                this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.map(item => {
                    if (linkByNumData[item.cpmvid]) {
                        item.additionalList = linkByNumData[item.cpmvid];
                    } else {
                        item.additionalList = [];
                    }
                    return item;
                });
            } else {
                this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.map(item => {
                    item.additionalList = [];
                    return item;
                });
            }

        },
        // 关系集合-按型号分组
        linkClassByAdditional(oldArray) {
            let linkByAdditionalData = {};
            if (oldArray.length > 0) {
                for (const item of oldArray) {
                    if (!linkByAdditionalData[item.cpavid]) {
                        linkByAdditionalData[item.cpavid] = [];
                        linkByAdditionalData[item.cpavid].push(item);
                    } else {
                        linkByAdditionalData[item.cpavid].push(item);
                    }
                }
            } else {
                linkByAdditionalData = {};
            }
            return linkByAdditionalData;
        },

        // 获取泵送价格字典
        getPumpList() {
            this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=303-BS0&type=3')
                .then(res => {
                    if (res) {
                        this.pumpingHeightSelectData = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus">
    .contractPriceForm_see
        padding .2rem!important
        height 100%!important
        background #fff!important
        overflow-y auto!important
        .el-dialog__header
            background #4f67ad
            color #fff
            padding 10px 20px 10px
            .el-dialog__title
                color #fff
            .el-dialog__headerbtn
                top 10px
                color #fff
                font-size 18px
                .el-dialog__close
                    color #fff
        .el-dialog
            height 300px
            .el-dialog__body
                height calc(100% - 52px)
                overflow-y auto
                overflow-x hidden
                ul
                    display flex
                    justify-content space-between
                    flex-wrap wrap
        .tag
            width .82rem
            border 1px solid #EAEAEA
            height .32rem
            line-height .32rem
            margin-left .1rem
            color #333
            cursor pointer
            background #F7F7F7
            margin-bottom .1rem
            margin-top: .1rem;
            .tag-text
                float left
                width 100%
                white-space nowrap
                text-overflow ellipsis
                overflow hidden
                padding 0 .05rem
                text-align center
            .iconguanbi
                font-size .12rem
                color #979EA7
                float right
                margin-right .1rem
                width .14rem
            &:hover
                background #FEF8F8
                border 1px solid #D6000F
                color #D6000F
                .iconguanbi
                    color #D6000F
        .table-container
            width 100%
            margin-bottom .2rem
            &:last-child
                margin-bottom 0
            .custom-table
                border 1px solid #D7D7D7
                border-spacing 0px
                border-collapse collapse
                width 100%
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button
                    -webkit-appearance none !important
                .pump-select
                    .el-select
                        input
                            border 1px solid #DCDFE6 !important
                            height .32rem
                            line-height .32rem
                        .el-input__icon
                            line-height .32rem!important
                .text-center
                    text-align center
                td,th
                    border 1px solid #D7D7D7
                    border-collapse collapse
                    height .42rem
                    line-height .42rem
                    font-size .16rem;
                    padding-left .1rem
                    input
                        width 100%
                        height 100%
                        font-size 0.14rem
                        height 0.42rem
                        line-height 0.42rem
                        border none
                    .is-disabled
                        .el-input__inner
                            background-color unset
                            color #333
                        .el-radio__inner
                            background-color: #fff;
                            border: 1px solid #dcdfe6;
                        &.is-checked
                            .el-radio__inner::after
                                background-color: #fff;
                    .is-checked
                        .el-radio__inner
                            border-color: #409eff;
                            background: #409eff;
                    .el-radio__input.is-disabled+span.el-radio__label
                        color #333
                    .price-input
                        width 1rem
                        input
                            width 1rem
                            height .32rem
                            border 1px solid #D7D7D7
                    .icon009
                        font-size .18rem
                    .no-tag
                        color #C6C6C6
                    .tag-con
                        .tag-more
                            border 1px solid #0285FE
                            color #0285FE
                            border-radius .04rem
                            background #fff
                            text-align center
                            .tag-more-text
                                width 100%
                            &:hover
                                background #fff
                                border 1px solid #0285FE
                                color #0285FE
                                .iconguanbi
                                    color #0285FE
                    .del-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #D6000F
                        border 1px solid #D6000F
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                    .sure-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #0285FE
                        border 1px solid #0285FE
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                        margin-right .1rem
                .table-tit
                    background #EDF0F5
                    color #022782
                    font-weight bold
                    text-align center
                .column-tit
                    background #F6F8FC
                    color #022782
                    .line-input
                        width 1rem
                        border 1px solid #d7d7d7
                        margin .05rem
                        line-height .32rem
                        border-radius .05rem
                        input
                            line-height .32rem!important
                            height .32rem!important
</style>
